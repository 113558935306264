<template>
  <div class="add-modal">
    <a-modal
      v-model="showModal"
      title="新建"
      :maskClosable="false"
      width="80%"
      :keyboard="false"
      destroyOnClose
      @cancel="handleModalCancel"
    >
      <div class="header">
        <div class="search">
          <a-form-model
            layout="inline"
            ref="formRef"
            :rules="rules"
            :model="form"
          >
            <a-form-model-item label="用户类型">
              <a-select
                v-model="form.userType"
                style="width: 220px"
                placeholder="全部"
                allowClear
                @change="chengeUserType"
              >
                <a-select-option value="2">企业用户</a-select-option>
                <a-select-option value="1">个人用户</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              prop="name"
              label="企业名称"
              v-if="Number(form.userType) === 2"
            >
              <a-input
                allow-clear
                v-model="form.name"
                placeholder="请输入企业名称"
                @blur="searchEnterprise(true)"
              />
            </a-form-model-item>
            <a-form-model-item prop="callUserName" label="联系人">
              <a-input
                allow-clear
                v-model="form.callUserName"
                placeholder="请输入联系人"
                @blur="searchEnterprise(false)"
              />
            </a-form-model-item>
            <a-form-model-item prop="tel" label="联系电话">
              <a-input
                allow-clear
                v-model="form.tel"
                placeholder="请输入联系电话"
              />
            </a-form-model-item>
            <a-button type="primary" :disabled="isDisable" @click="hardwareSave"
              >保存
            </a-button>
          </a-form-model>
        </div>
      </div>
      <div class="count">写入成功：{{ hasWrite.length }}</div>
      <a-table
        rowKey="sequenceNbr"
        :columns="enterpriseColumns"
        :data-source="tableData"
        :scroll="{ x: 'max-content' }"
        :pagination="pagination"
        :loading="tableLoading"
      >
        <template slot="sortNo" slot-scope="text">
          {{ text }}
        </template>
        <template slot="status" slot-scope="text, record">
          <span
            :class="
              hasWrite.includes(`${record.serial}`) ? 'colorGreen' : 'colorRed'
            "
          >
            {{
              record
                ? hasWrite.includes(`${record.serial}`)
                  ? '写入成功'
                  : '待写入'
                : '暂未检测到加密锁'
            }}
          </span>
        </template>

        <template
          slot="callUserName"
          slot-scope="text, record"
          v-if="Number(form.userType) === 2"
        >
          <a-input
            v-if="record && !hasWrite.includes(`${record.serial}`)"
            allow-clear
            v-model="record.callUserName"
            placeholder="请输入联系人名称"
          />
          <span v-else>
            {{ record.callUserName }}
          </span>
        </template>

        <template
          slot="tel"
          slot-scope="text, record"
          v-if="Number(form.userType) === 2"
        >
          <a-input
            v-if="record && !hasWrite.includes(`${record.serial}`)"
            allow-clear
            v-model="record.tel"
            placeholder="联系人方式"
          />
          <span v-else>
            {{ record.tel }}
          </span>
        </template>
        <template slot="serial" slot-scope="text">
          {{ text }}
        </template>

        <template slot="month" slot-scope="text, record">
          <a-select
            v-model="record.month"
            style="width: 220px"
            placeholder="使用时长"
            allowClear
            @change="typeChange"
            :disabled="record && hasWrite.includes(`${record.serial}`)"
          >
            <a-select-option value="1">一个月</a-select-option>
            <a-select-option value="3">一季度</a-select-option>
            <a-select-option value="12">一年</a-select-option>
            <a-select-option value="24">两年</a-select-option>
            <a-select-option value="36">三年</a-select-option>
          </a-select>
        </template>
        <template slot="busId" slot-scope="text">
          {{ text }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button
            @click="hardwareWrite(record)"
            :loading="writwLoading"
            v-if="!hasWrite.includes(`${record.serial}`)"
            >写入
          </a-button>
        </template>
      </a-table>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleModalCancel">
          关闭
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  hardwareEnterprise,
  getHardWarepoolInitStatus,
  hardwareSave,
  hardWarepoolAdd,
} from '@/api/lock';
import axios from 'axios';
import { SMS_CODE_TYPE } from '@/interface';
import { isMobile } from '@/utils/validate';

const request = axios.create({
  baseURL: 'http://localhost:20002/controller/',
  timeout: 10000,
});
let timter = null;
export default Vue.extend({
  name: 'add-modal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateMobile = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入联系电话'));
      }
      if (!isMobile(value)) {
        return callback(new Error('联系电话格式有误'));
      }
    };
    return {
      rules: {
        tel: [
          {
            required: true,
            validator: validateMobile,
            validateTrigger: 'blur',
          },
        ],
        callUserName: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
      },
      sum: 0,
      enid: 0,
      newData: [], //新疆表格数据
      form: {
        userType: '2',
        name: '',
        callUserName: '',
        tel: '',
      },
      hasWrite: [], //已写入设备
      tableLoading: false,
      writwLoading: false,
      extraList: [
        {
          title: '序号',
          dataIndex: 'sortNo',
          width: '100px',
        },
        {
          title: '员工姓名',
          dataIndex: 'callUserName',
          width: '250px',
          scopedSlots: { customRender: 'callUserName' },
        },
        {
          title: '联系电话',
          dataIndex: 'tel',
          width: '250px',
          scopedSlots: { customRender: 'tel' },
        },
      ],
      nowList: [
        {
          title: '加密锁业务号',
          dataIndex: 'busId',
          width: '220px',
        },
        {
          title: '加密锁设备号',
          dataIndex: 'serial',
          width: '220px',
          scopedSlots: { customRender: 'serial' },
        },
        {
          title: '使用期限',
          dataIndex: 'month',
          width: '120px',
          scopedSlots: { customRender: 'month' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: '200px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      isDisable: false, // 保存按钮是否禁用
      enterpriseColumns: [],
    };
  },
  watch: {
    showModal(nvalue, ovalue) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      if (nvalue) {
        this.reset();
        timter = setInterval(() => {
          self.getLoaclFn();
        }, 5000);
      } else {
        clearInterval(timter);
      }
    },
  },
  mounted() {
    this.chengeUserType(this.form.userType);
  },
  methods: {
    renderData(list, map) {
      let tableData = this.tableData;
      if (tableData.length == 0) {
        let arr = [];
        for (let i = 0; i < list.length; i++) {
          arr.push({
            serial: list[i],
            callUserName: map.get(list[i]) ? map.get(list[i]).callUserName : '',
            tel: map.get(list[i]) ? map.get(list[i]).tel : '',
            month: '1',
          });
        }
        this.tableData = arr;
      } else {
        let size = tableData.length;
        let arr = [];
        for (let j = 0; j < list.length; j++) {
          let ok = false;
          for (let i = 0; i < size; i++) {
            if (list[j] == tableData[i].serial) {
              ok = true;
              arr.push(tableData[i]);
            }
          }
          if (!ok) {
            arr.push({
              serial: list[j],
              callUserName: map.get(list[j])
                ? map.get(list[j]).callUserName
                : '',
              tel: map.get(list[j]) ? map.get(list[j]).tel : '',
              month: '1',
            });
          }
        }
        arr.map((item, index) => {
          item.sortNo = index + 1;
          item.busId = btoa(item.serial);
          console.log(item.serial, btoa('item.serial'));
        });
        this.tableData = arr;
      }
    },
    getLoaclFn() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      request
        .request({
          url: `/manager/getSn`,
          method: 'GET',
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.newData = res.data.data;
            self.hardWarepoolInitStatus(res.data.data);
          }
          console.log(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    hardWarepoolInitStatus(list) {
      getHardWarepoolInitStatus(list).then((res) => {
        this.hasWrite = [];
        let map = new Map();
        for (let i = 0; i < res.result.length; i++) {
          let item = res.result[i];
          this.hasWrite.push(item.serial + '');
          map.set(item.serial, item);
        }
        this.renderData(this.newData, map);
      });
    },
    handleModalCancel() {
      this.reset();
      this.$emit('update:showModal', false);
    },
    typeChange(value) {
      console.log('value', value);
    },
    hardwareWrite(record) {
      console.log('this.enid', this.enid);
      if (this.enid) {
        this.writwLoading = true;

        hardWarepoolAdd({ ...record, enterpriseId: this.enid }).then((res) => {
          if (res.status === 200 && res.result) {
            this.sum++;
            this.hasWrite.push(record.serial);
            this.$message.success('写入成功');
          } else {
            this.$message.error(res.message);
          }
          this.writwLoading = false;
        });
      } else {
        this.$message.error('请选择企业/个人用户');
      }
    },
    hardwareSave(record) {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          hardwareSave(this.form).then((res) => {
            console.log(res);
            if (res.status === 200 && res.result) {
              this.$message.success('新建成功');
              this.getLoaclFn();
              hardwareEnterprise(this.form).then((res) => {
                if (res.status === 200 && res.result.length > 0) {
                  this.isDisable = true;
                  this.form.callUserName = res.result[0].callUserName;
                  this.form.tel = res.result[0].tel;
                  this.enid = res.result[0].sequenceNbr;
                  console.log('++++++++++', res.result);
                }
              });
              this.init();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    searchEnterprise(flag) {
      let from = {};
      if (!flag && this.form.userType === '2') return;
      if (this.form.userType === '1') {
        if (!this.form.callUserName) return;
        from.callUserName = this.form.callUserName;
        from.userType = this.form.userType;
      } else {
        if (!this.form.name) return;
        from.name = this.form.name;
        from.userType = this.form.userType;
      }

      hardwareEnterprise(from).then((res) => {
        if (res.status === 200 && res.result.length > 0) {
          this.isDisable = true;
          this.form.callUserName = res.result[0].callUserName;
          this.form.tel = res.result[0].tel;
          this.enid = res.result[0].sequenceNbr;
        } else {
          this.isDisable = false;
          if (flag && Number(this.form.userType) === 2) {
            this.form.callUserName = '';
          }
          this.form.tel = '';
          this.enid = 0;
        }
      });
    },

    //初始化
    reset() {
      this.isDisable = false;
      this.tableData = [];
      this.form.name = '';
      this.form.callUserName = '';
      this.form.tel = '';
      this.$refs.formRef?.resetFields();
    },
    chengeUserType(eve) {
      console.log('切换', eve);
      if (eve === '1') {
        this.enterpriseColumns = [
          {
            title: '序号',
            dataIndex: 'sortNo',
            width: '100px',
          },
          ...this.nowList,
        ];
      } else {
        this.enterpriseColumns = [...this.extraList, ...this.nowList];
      }
      console.log('this.enterpriseColumns', this.enterpriseColumns);
      this.reset();
    },
  },
});
</script>

<style lang="less" scoped>
.ant-form {
  display: flex;
}

.colorGreen {
  color: #52c41a;
}

.colorRed {
  color: #f64042;
}

.count {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #707070;
  margin-bottom: 12px;
}
</style>
